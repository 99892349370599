













































import { prefix } from '@/config/global';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import rankFilter from '@/components/rankFilter/index.vue';
import rankTable from '@/components/rankTable/index.vue';
import {
  getMapCenter, initMap, drawMall, clearMall, zoomUp, zoomDown, drawCircle, drawLabel, clearOverlay,
} from '@/components/map/map';
import spinCard from '@/components/SpinCard/index.vue';
import { getContainer, updateTimeText } from '@/components';
import competeRegionInfo from '@/components/competeRegionInfo/index.vue';
import mallName from '@/components/mallName/index.vue';
import { addCompetedMallId, getAllMallByAdcode, selUpdateTime } from '@/services/competitionApi';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */

@Component({
  components: {
    spinCard,
    rankFilter,
    rankTable,
    competeRegionInfo,
    mallName,
  },
  filters: {
    formatDate(date: string) {
      const dates = (date.split(' ')[0]).split('-');
      return dates;
    },
  },
})


export default class Regional extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  competitionList!: any [];

  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  competeCount!: number;
  prefix: string = prefix;
  spinShow = false;
  mallMarker!: any;
  map: any;
  duration = 'xm';
  mallId = this.$store.state.mall.mallId;
  date = this.$store.state.mall.date;
  dates = this.$store.state.mall.dates;
  boundary = this.$store.state.mall.boundary;
  center = this.$store.state.mall.center;
  params!: any;
  rowActive = 0;
  initZom = 12;
  mapHeight = '0';
  competeGridData: any = [];
  $jQ: any;
  nMallMarkerAry: any = [];
  adcode = 0;
  mapOverlayConfig: any = {
    18: {
      radius: 10,
      nameVisible: true,
      strokeWeight: 5,
    },
    17: {
      radius: 20,
      nameVisible: true,
      strokeWeight: 5,
    },
    16: {
      radius: 40,
      nameVisible: true,
      strokeWeight: 5,
    },
    15: {
      radius: 80,
      nameVisible: false,
      strokeWeight: 5,
    },
    14: {
      radius: 100,
      nameVisible: false,
      strokeWeight: 5,
    },
    13: {
      radius: 120,
      nameVisible: false,
      strokeWeight: 3,
    },
    12: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    11: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    10: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    9: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    8: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    7: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    6: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    5: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    4: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    3: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    2: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
    1: {
      radius: 200,
      nameVisible: false,
      strokeWeight: 3,
    },
  };
  markerLabelStyle = {
    color: 'white',
    border: 'none',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    transform: 'translateY(-50%) translateX(-50%)',
    zIndex: 10,
  };
  fillColor = new (window as any).qq.maps.Color(69, 131, 255, 0.55);
  competitionObj: any = {};
  competitionAry: any = [];
  competeMallId = 0;
  competitionName = '';
  competitionInfo: any = {};
  circleAry: any = [];
  nameLabelAry: any = [];
  beforeCompetitionList: any = [];
  mallData: any = [];
  labelStyle = {
    zIndex: 12,
    transform: 'translateY(-126%) translateX(-48%)',
    backgroundColor: 'white',
    color: '#030227',
  };
  clickLabel: any;
  $dayjs: any;
  title='添加竞品';
  cUpdateDate  = '';
  data: any= [];
  cListHg = 33;
  mounted(): void {
    this.boundary = this.$store.state.mall.boundary;
    this.center = this.$store.state.mall.center;
    this.mapHeight = `${getContainer(72)}px`;
    this.map = initMap('map-container', this.initZom, this.center);
    this.mapZoomEvent();
    this.pageHandlers();
  }

  async pageHandlers(): Promise<void> {
    this.spinShow = true;

    setTimeout(() => {
      this.pageHandler();
    }, 100);
  }
  updateTimeText(date: string): string {
    return updateTimeText(date);
  }

  rowClick(item: any): void{
    const { center_gcj: centerGcj } = item;
    const center = getMapCenter(centerGcj);
    this.map.setCenter(center);
    this.map.setZoom(16);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async pageHandler(): Promise<void> {
    clearOverlay(this.clickLabel);
    this.competitionObj = {};
    this.competitionAry = [];
    const { mall } = this.$store.state;
    const { center, adcode, mallId: mMallId } = mall;
    this.mallId = mMallId;
    this.competitionObj[mMallId] = 1;
    this.center = center;
    // 主场中心标识渲染
    if (this.center) {
      const center = getMapCenter(this.center);
      this.map.setCenter(center);
      clearMall(this.mallMarker);
      this.mallMarker = drawMall(this.map, center);
    }
    // 多个竞品标识清除
    this.nMallMarkerAry.forEach((item: any) => {
      clearMall(item);
    });

    // circle 配置项
    const zoom = this.map.getZoom() ? this.map.getZoom() : 16;
    const {
      nameVisible,
      radius,
    } = this.mapOverlayConfig[zoom];
    this.nMallMarkerAry = [];
    // 多个竞品标识渲染
    this.competitionList.forEach((item: any) => {
      const { mall_id: mallId, center_gcj: centerGcj } = item;
      const center = getMapCenter(centerGcj);
      const nMallMarker = drawMall(this.map, center, 1);
      this.nMallMarkerAry.push(nMallMarker);
      this.competitionObj[mallId] = 1;
      this.competitionAry.push(item);
    });
    this.setListHg();

    // 新旧竞品对比 减少http请求
    if (this.adcode === adcode) {
      this.beforeCompetitionList.forEach((item: any) => {
        const { mallId } = item;
        if (!this.competitionObj[mallId]) {
          this.drawOverlay(item, radius, nameVisible);
        }
      });

      this.circleAry.forEach((item: any, index: any) => {
        const { mall_id: mallId } = item.item;
        if (this.competitionObj[mallId]) {
          item.setMap(null);
          // this.circleAry.splice(index, 1);
          delete this.circleAry[index];
        }
      });
      this.spinShow = false;
      return;
    }
    this.clearOverlay();
    this.adcode = adcode;
    const params = {
      adcode: typeof(this.adcode) === 'string' ? parseInt(this.adcode, 10) : this.adcode,
    };
    const res = await getAllMallByAdcode(params);
    const data = res.data ? res.data : [];
    this.data = data;
    // 圆形覆盖物
    data.forEach((item: any) => {
      this.drawOverlay(item, radius, nameVisible);
    });
    this.spinShow = false;
  }
  setListHg(): void{
    if (this.competitionAry.length <= 10) {
      this.cListHg = this.competitionAry.length * 33;
    } else {
      this.cListHg = 330;
    }
  }

  async mapClickHandler(event: any): Promise<void> {
    const { target } = event;
    const { className } = target;
    if (className === 'add-competition') {
      // 确定要将(兴万和购物广场)添加到您的竞品列表吗?
      // 竞品次数用光
      if (this.competitionAry.length >= this.competeCount) {
        const text = '当前竞品添加次数已使用完，如需添加更多竞品，请联系销售经理增加配额';
        this.$Modal.info({
          title: this.title,
          content: text,
          okText: '知道啦',
          onOk: async () => {
            console.log(text);
          },
        });
        return;
      }
      const text2 = `确定要将(${this.competitionName})添加到您的竞品列表吗?`;
      // 正常添加竞品
      this.$Modal.confirm({
        title: this.title,
        content: text2,
        okText: '确认',
        onOk: async () => {
          this.addCompetitionData();
        },
      });
    }
  }
  async addCompetitionData(): Promise<void> {
    const params = {
      mall_id: this.mallId,
      compete_mall_id: this.competeMallId,
    };
    const res1 = await addCompetedMallId(params);
    const res2 = await selUpdateTime(params);
    const data2 = res2.data ? res2.data : { update_datetime: this.$dayjs() };
    const cUpdateDate = data2.update_datetime;
    this.competitionInfo.update_datetime = cUpdateDate;
    this.cUpdateDate = this.$dayjs(cUpdateDate).format('YYYYMMDD');
    if (res1.status !== 0) {
      const text = res1.msg ? res1.msg : '添加失败';
      setTimeout(() => {
        this.$Modal.error({
          title: this.title,
          content: text,
          okText: '确认',
          onOk: async () => {
            console.log('失败的步骤');
          },
        });
      }, 200);
      return;
    }
    this.addCompetedSuccess();
    const text = `(${this.competitionName})已添加到您的竞品列表，正在产出对比数据，请于24小时后在客留通-竞争烈度模块进行查看。`;
    setTimeout(() => {
      // 正常添加竞品
      this.$Modal.success({
        title: this.title,
        content: text,
        okText: '确认',
        onOk: async () => {
          clearOverlay(this.clickLabel);
          console.log('添加完成的步骤');
        },
      });
    }, 200);
  }

  addCompetedSuccess(): void{
    // 单个竞品标识渲染
    const { mall_id: mallId, center_gcj: centerGcj } = this.competitionInfo;
    const center = getMapCenter(centerGcj);
    const nMallMarker = drawMall(this.map, center, 1);
    this.nMallMarkerAry.push(nMallMarker);
    this.competitionObj[mallId] = 1;
    this.competitionAry.push(this.competitionInfo);
    const cMallId = this.competitionInfo.mall_id;
    this.setListHg();
    // 新旧竞品对比 减少http请求
    this.circleAry.forEach((item: any, index: any) => {
      const { mall_id: mallId } = item.item;
      if (cMallId === mallId) {
        item.setMap(null);
        delete this.circleAry[index];
      }
    });
  }

  drawOverlay(item: any, radius: number, nameVisible: boolean): any {
    const { mall_id: mallId, center_gcj: centerGcj, name } = item;
    const center = getMapCenter(centerGcj);
    if (!this.competitionObj[mallId]) {
      const circle = drawCircle(
        this.map,
        center,
        0,
        mallId,
        radius,
        0,
        item,
        3,
        this.fillColor,
      );
      this.circleAry.push(circle);
      this.circlesClick(circle);
    }
    const nameContent = `<div class="theme-text-color" style="width: 100%;height:100%;text-align:center;font-size: 14px;margin-top:54px;">${name}</div>`;
    const nameLabel = drawLabel(
      this.map,
      center,
      nameContent,
      0,
      mallId,
      this.markerLabelStyle,
      nameVisible,
    );
    this.nameLabelAry.push(nameLabel);
  }

  clearOverlay(): void {
    if (this.circleAry.length === 0) {
      return;
    }
    // this.clearPolygon(this.activePolygon);
    this.nameLabelAry.forEach((item: any, index: number) => {
      if (this.circleAry[index]) {
        this.circleAry[index].setMap(null);
      }
      this.nameLabelAry[index].setMap(null);
    });
    this.circleAry = [];
    this.nameLabelAry = [];
    // this.activePolygon = '';
  }

  circlesClick(circle: any): void {
    (window as any).qq.maps.event.addListener(
      circle,
      'click',
      (event: any) => {
        const { target } = event;
        this.clearStrokeWeight();
        const { index, item } = target;
        const { name, mall_id: mallId } = item;
        const zoom = this.map.getZoom();
        const { strokeWeight } = this.mapOverlayConfig[zoom];
        event.target.setStrokeWeight(strokeWeight);
        event.target.active = 1;
        this.rowActive = index;
        this.competitionName = name;
        this.competeMallId = mallId;
        this.competitionInfo = item;
        clearOverlay(this.clickLabel);
        this.clickLabel = this.drawClickInfo(target.item);
      },
    );
  }

  drawClickInfo(item: any) {
    const { name, center_gcj: centerGcj, province, city, district } = item;
    const center = getMapCenter(centerGcj);
    const location = province + city + district;
    const content = `<div style='border:none !important;user-select:none;padding:10px 20px;position:relative;'><h2 style = 'font-size:20px;padding-right:90px;'>${name}</h2><p style='margin: 10px 0px;font-size:16px;'>${location}</p><div style="margin-top:16px;display:flex;justify-content:center;"><div class='add-competition' style='color:#FFF;text-align:center;cursor:pointer;font-size:14px;width: 110px;height: 28px;border-radius: 5px;line-height: 28px;background: #4583FF;'>添加为竞品</div></div></div>`;
    // <div style='position: absolute;width: 0px;height: 0px;left:43%;bottom:-17%;line-height: 0px;
    // border-width: 20px 16px 0;border-style: solid dashed dashed dashed;border-left-color: transparent;
    // border-right-color: transparent;color: white;'></div>
    const label = drawLabel(
      this.map,
      center,
      content,
      0,
      0,
      this.labelStyle,
      true,
    );
    return label;
  }
  clearStrokeWeight(): void {
    this.circleAry.forEach((item: any) => {
      if (item.active === 1) {
        item.setStrokeWeight(0);
        // eslint-disable-next-line no-param-reassign
        item.active = 0;
      }
    });
  }

  mapZoomEvent(): void {
    (window as any).qq.maps.event.addListener(
      this.map,
      'zoom_changed',
      (event: Event) => {
        const { zoom } = event.target as any;
        let config = this.mapOverlayConfig[zoom];
        if (!config) {
          config = {
            radius: 200,
            polygonVisible: false,
            nameVisible: false,
            rankVisible: false,
          };
        }
        const {
          radius,
          nameVisible,
          strokeWeight,
        } = config;
        // if (this.activePolygon) {
        //   this.activePolygon.setVisible(polygonVisible);
        // }
        this.nameLabelAry.forEach((item: any, index: number) => {
          if (this.circleAry[index]) {
            this.circleAry[index].setRadius(radius);
            if (this.circleAry[index].active === 1) {
              this.circleAry[index].setStrokeWeight(strokeWeight);
            }
          }
          const nameLabelItem = this.nameLabelAry[index];
          nameLabelItem.setVisible(nameVisible);
        });
      },
    );
  }
  @Watch('competitionList')
  changeCompetedModelObj(e: any, beofreData: any): void {
    this.beforeCompetitionList = beofreData;
    this.pageHandlers();
  }

  returnPageClickHandler(): void{
    this.$emit('returnPageClickHandler');
  }

  zoomUp(): void {
    zoomUp(this.map);
  }

  zoomDown(): void {
    zoomDown(this.map);
  }

  mallPostionClickHandler(): void{
    const center = getMapCenter(this.center);
    this.map.panTo(center);
  }
}
