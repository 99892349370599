





















































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { getContainer, updateTimeText } from '@/components';
import { Vue, Component } from 'vue-property-decorator';
import addCompetition from './addCompetition/index.vue';
import regionList from '@/components/regionList/index.vue';
import { getCompetedMallByUserId } from '@/services/api';


@Component({
  components: {
    regionList,
    addCompetition,
  },
})

// TODO  Agent 区域数据需处理，类型需合适定义;
export default class Competition extends Vue {
  addCompetitionStatus = false;
  containerHeight = 0;
  province = '';
  city = '';
  name = '';
  mallId = NaN;
  regionColumnPage = [
    {
      title: '竞品名称',
      key: 'name',
      align: 'center',
    },
    {
      title: '省份',
      key: 'province',
      align: 'center',
    },
    {
      title: '城市',
      key: 'city',
      align: 'center',
    },
    {
      title: '区县',
      key: 'district',
      align: 'center',
    },
    {
      title: '状态',
      key: 'status',
      align: 'center',
      render(h: any, params: any) {
        const { update_datetime: updateTime } = params.row;
        const text = updateTimeText(updateTime, '服务中');
        return h('div', {
          domProps: {
            innerText: text,
          },
        });
      },
    },
  ];
  regionData = [];
  regionLoading = false;
  competitionList: any = [];
  competitionLth = 0;
  competeCount = 0;
  competeStatus = false;
  tableHg = 400;
  competionStatus = true;
  created(): void{
    this.containerHeight = getContainer(64);
  }
  async getCompetedData(): Promise<void> {
    const params = {
      mall_id: this.mallId,
    };
    const competedRes = await getCompetedMallByUserId(params);
    this.competitionList = competedRes.data ? competedRes.data : [];
    this.competitionLth = this.competitionList.length;
    // 竞品次数用光后禁用按钮
    if (Number(this.competeCount) <= Number(this.competitionLth)
    || Number(this.competeCount) === 0) this.competeStatus = true;
  }

  mallChangeHandler(mallInfos: any): void{
    const { province, city, name, mall_id: mallId, compete_count: competeCount } = mallInfos;
    this.province = province;
    this.city = city;
    this.name = name;
    this.mallId = mallId;
    this.competeCount = Number(competeCount);
    this.competeStatus = false;
    this.getCompetedData();
  }
  updateTimeText(date: string): string {
    return updateTimeText(date);
  }

  returnPageClickHandler(): void{
    this.competionStatus = true;
    this.addCompetitionStatus = false;
    this.getCompetedData();
  }

  addCompetionClickHandler(): void{
    this.competionStatus = false;
    this.addCompetitionStatus = true;
  }
}
