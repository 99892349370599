import { getAxiosDataNode } from '@/services/apiConfig';
import { Result } from '@/types/services';
/*
* 接口类型：Get/Post
* 编码类型：Content-Type:application/json
*/

// 根据adcode获取城市下所有0、1、2状态的商场区域
async function getAllMallByAdcode(params = {}): Promise<any> {
  const url = 'mall/getAllMallByAdcode';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 添加竞品接口
async function addCompetedMallId(params = {}): Promise<any> {
  const url = 'usercmid/addCompetedMallId';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}

// 根据adcode获取城市下所有0、1、2状态的商场区域
async function selUpdateTime(params = {}): Promise<any> {
  const url = 'usercmid/selUpdateTime';
  const getData = await getAxiosDataNode(params, url);
  const res = getData as Result<any>;
  return res;
}


export {
  getAllMallByAdcode,
  addCompetedMallId,
  selUpdateTime,
};
